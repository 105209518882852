// Welcome to Code in Framer
// Get Started: https://www.framer.com/developers/

import { addPropertyControls, ControlType } from "framer"
import { useState, useEffect, cloneElement } from "react"
import { motion, AnimatePresence, wrap, HTMLMotionProps } from "framer-motion"

interface Props extends Omit<HTMLMotionProps<"div">, "layout"> {
    overlay?: () => void
    component: any
    arrows: any
    close: any
    showThumbnail: boolean
    showPageCount: boolean
    maxContainerWidth: number
}

export default function LightboxAutoOpen(props) {
    const {
        overlay,
        component,
        arrows,
        close,
        showThumbnail,
        showPageCount,
        maxContainerWidth,
    } = props
    const [loadedItems, setLoadedItems] = useState([])
    const [[page, direction], setPage] = useState([0, 0])
    const [showComponent, setShowComponent] = useState(false)
    const [isPhone, setPhone] = useState(false)
    const paginate = (newDirection: number) => {
        setPage([page + newDirection, newDirection])
    }

    const activeImageIndex = wrap(0, loadedItems.length, page)

    const skipToImage = (imageId) => {
        let changeDirection
        if (imageId > activeImageIndex) {
            changeDirection = 1
        } else if (imageId < activeImageIndex) {
            changeDirection = -1
        }
        setPage([imageId, changeDirection])
    }

    useEffect(() => {
        window.addEventListener("resize", () => {
            setPhone(!window.matchMedia("(min-width: 768px)").matches)
        })

        setPhone(!window.matchMedia("(min-width: 768px)").matches)

        return () => {
            window.removeEventListener("resize", null)
        }
    }, [])

    useEffect(() => {
        const body = document.getElementsByTagName("body")[0]
        // get image data
        const imageNode = document.querySelectorAll(
            `.${component[0]?.props.className} img`
        )
        // get title data
        const titleNode = document.querySelectorAll(
            `.${component[0]?.props.className} .framer-text`
        )
        // responsive grid
        const parentNode = document.querySelectorAll(
            `.${component[0]?.props.className}`
        )[0]
        parentNode?.setAttribute(
            "style",
            `width: auto; max-width:${maxContainerWidth + "px"}; display: none`
        )

        const data = []
        for (let i = 0; i < (imageNode.length || titleNode.length); i++) {
            data.push({
                image: imageNode[i]?.src || "",
                title: titleNode[i]?.innerText || "",
            })
        }

        // hide scrolling on modal popup
        if (showComponent) {
            body.style.overflow = "hidden"
            body.style.height = "100%"
        } else {
            body.style.overflow = "auto"
            body.style.height = "auto"
        }

        console.log(data)

        skipToImage(0)
        setLoadedItems(data)
    }, [showComponent])

    //swipe gesture
    const swipeConfidenceThreshold = 1000
    const swipePower = (offset: number, velocity: number) => {
        return Math.abs(offset) * velocity
    }
    const variants = {
        open: { opacity: 1, zIndex: 20 },
        closed: { opacity: 0, zIndex: 0 },
    }

    const sliderVariants = {
        enter: (direction: number) => {
            return {
                x: 0,
                opacity: 0,
            }
        },
        center: {
            zIndex: 20,
            x: 0,
            opacity: 1,
        },
        exit: (direction: number) => {
            return {
                zIndex: 0,
                x: 0,
                opacity: 0,
            }
        },
    }

    useEffect(() => {
        setShowComponent(true)
    }, [])

    return (
        <div style={{ width: "100%", height: "100%", display: "flex" }}>
            {/*Cms Component*/}
            <div
                style={{
                    width: "fit-content",
                    height: "fit-content",
                    color: "#515151",
                }}
            >
                {component && component[0]
                    ? cloneElement(component[0], {
                          style: {
                              ...component[0].props.style,
                              display: "none",
                          },
                      })
                    : "Connect collection!"}
            </div>
            {/*Light Box Component*/}
            <motion.div
                initial={false}
                animate={showComponent ? "open" : "closed"}
                variants={variants}
                transition={{
                    duration: 0.4,
                    ease: [0.56, 0.03, 0.12, 1.04],
                }}
            >
                {loadedItems.length > 0 && showComponent && (
                    <div
                        style={{
                            position: "fixed",
                            inset: 0,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            perspective: "3000px",
                            background: "#000",
                            zIndex: 20,
                        }}
                    >
                        <AnimatePresence initial={false} custom={direction}>
                            <motion.img
                                key={page}
                                src={loadedItems[activeImageIndex]?.image}
                                custom={direction}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: {
                                        type: "spring",
                                        stiffness: 300,
                                        damping: 30,
                                    },
                                    opacity: { duration: 0.4 },
                                }}
                                variants={sliderVariants}
                                style={{
                                    width: "100%",
                                    height: "78vh",
                                    display: "flex",
                                    objectFit: "contain",
                                    objectPosition: "center",
                                    position: "absolute",
                                    margin: "auto",
                                    inset: 0,
                                    padding: "10px",
                                    cursor: "grab",
                                }}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={0.01}
                                onDragEnd={(e, { offset, velocity }) => {
                                    const swipe = swipePower(
                                        offset.x,
                                        velocity.x
                                    )

                                    if (swipe < -swipeConfidenceThreshold) {
                                        paginate(1)
                                    } else if (
                                        swipe > swipeConfidenceThreshold
                                    ) {
                                        paginate(-1)
                                    }
                                }}
                            />
                        </AnimatePresence>

                        {/*navigation section*/}
                        <div
                            style={{
                                position: "absolute",
                                inset: "10px",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                onClick={() => paginate(-1)}
                                style={{ cursor: "pointer", zIndex: 20 }}
                            >
                                <div
                                    style={{
                                        width: arrows.size + "px",
                                        display: "flex",
                                        color: arrows.color,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: arrows.leftIcon,
                                    }}
                                ></div>
                            </div>
                            <div
                                onClick={() => paginate(1)}
                                style={{ cursor: "pointer", zIndex: 20 }}
                            >
                                <div
                                    style={{
                                        width: arrows.size + "px",
                                        display: "flex",
                                        color: arrows.color,
                                    }}
                                    dangerouslySetInnerHTML={{
                                        __html: arrows.rightIcon,
                                    }}
                                ></div>
                            </div>
                        </div>
                        {/*page count section*/}
                        {showPageCount && (
                            <div
                                style={{
                                    position: "absolute",
                                    top: 0,
                                    left: "10px",
                                    display: "flex",
                                    padding: "10px",
                                    background: "rgba(0,0,0,0.6)",
                                    zIndex: 20,
                                    fontSize: "18px",
                                    color: "#fff",
                                }}
                            >
                                {activeImageIndex +
                                    1 +
                                    "/" +
                                    loadedItems.length}
                            </div>
                        )}
                        {/*close section*/}
                        <div
                            style={{
                                position: "absolute",
                                top: 0,
                                right: "10px",
                                display: "flex",
                                cursor: "pointer",
                                zIndex: 20,
                            }}
                            onClick={() => {
                                if (overlay) {
                                    overlay?.()
                                }

                                setShowComponent(false)
                            }}
                        >
                            <div
                                style={{
                                    width: close.size + "px",
                                    display: "flex",
                                    color: close.color,
                                }}
                                dangerouslySetInnerHTML={{
                                    __html: close.closeIcon,
                                }}
                            ></div>
                        </div>

                        {/*thumbnail section*/}
                        {!showThumbnail || (
                            <div
                                style={{
                                    position: "absolute",
                                    bottom: 0,
                                    left: 0,
                                    right: 0,
                                    display: isPhone ? "none" : "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    gap: "10px",
                                    background: "rgba(0,0,0,0.6)",
                                    zIndex: 20,
                                    padding: "10px",
                                }}
                            >
                                {showThumbnail && (
                                    <div
                                        style={{
                                            display: "grid",
                                            gridAutoFlow: "column",
                                            gap: "10px",
                                            maxWidth: "100%",
                                            overflow: "overlay hidden",
                                        }}
                                    >
                                        {loadedItems.map((item, index) => (
                                            <div
                                                key={index}
                                                onClick={() =>
                                                    skipToImage(index)
                                                }
                                                style={{
                                                    width: "100px",
                                                    height: "100px",
                                                    opacity: `${
                                                        index ===
                                                        activeImageIndex
                                                            ? 1
                                                            : 0.5
                                                    }`,
                                                }}
                                            >
                                                <img
                                                    src={item?.image}
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        objectFit: "cover",
                                                        objectPosition:
                                                            "center",
                                                        borderRadius: "10px",
                                                        cursor: "pointer",
                                                    }}
                                                    alt=""
                                                />
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </motion.div>
        </div>
    )
}

LightboxAutoOpen.defaultProps = {
    component: [],
    maxContainerWidth: 1000,
    arrows: {
        leftIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm48-88a8,8,0,0,1-8,8H107.31l18.35,18.34a8,8,0,0,1-11.32,11.32l-32-32a8,8,0,0,1,0-11.32l32-32a8,8,0,0,1,11.32,11.32L107.31,120H168A8,8,0,0,1,176,128Z"></path></svg>`,
        rightIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M128,24A104,104,0,1,0,232,128,104.11,104.11,0,0,0,128,24Zm0,192a88,88,0,1,1,88-88A88.1,88.1,0,0,1,128,216Zm45.66-93.66a8,8,0,0,1,0,11.32l-32,32a8,8,0,0,1-11.32-11.32L148.69,136H88a8,8,0,0,1,0-16h60.69l-18.35-18.34a8,8,0,0,1,11.32-11.32Z"></path></svg>`,
        size: 30,
        color: "#ffffff",
    },
    close: {
        closeIcon: `<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" viewBox="0 0 256 256"><path d="M205.66,194.34a8,8,0,0,1-11.32,11.32L128,139.31,61.66,205.66a8,8,0,0,1-11.32-11.32L116.69,128,50.34,61.66A8,8,0,0,1,61.66,50.34L128,116.69l66.34-66.35a8,8,0,0,1,11.32,11.32L139.31,128Z"></path></svg>`,
        size: 30,
        color: "#ffffff",
    },
    showThumbnail: true,
    showPageCount: true,
}
addPropertyControls(LightboxAutoOpen, {
    overlay: {
        type: ControlType.EventHandler,
    },
    component: {
        title: "CMS Component",
        type: ControlType.ComponentInstance,
        description:
            "Note:\n place the CMS collection list outside the frame, then only it will show in the dropdown list",
    },
    maxContainerWidth: {
        type: ControlType.Number,
        title: "Grid Width",
        max: 3000,
    },
    arrows: {
        type: ControlType.Object,
        title: "Nav Styles",
        controls: {
            leftIcon: {
                type: ControlType.String,
                title: "Left Icon",
            },
            rightIcon: {
                type: ControlType.String,
                title: "Right Icon",
            },
            size: {
                type: ControlType.Number,
                title: "Icon Size",
            },
            color: {
                type: ControlType.Color,
                title: "Icon Color",
                description:
                    "Note: \n if you want to replace default svg icon, please change 'fill' property to 'currentColor', then only icon color will be overide",
            },
        },
    },
    close: {
        type: ControlType.Object,
        title: "Close Styles",
        controls: {
            closeIcon: {
                type: ControlType.String,
                title: "Close Icon",
            },
            size: {
                type: ControlType.Number,
                title: "Icon Size",
            },
            color: {
                type: ControlType.Color,
                title: "Icon Color",
                description:
                    "Note: \n if you want to replace default svg icon, please change 'fill' property to 'currentColor', then only icon color will be overide",
            },
        },
    },
    showThumbnail: {
        type: ControlType.Boolean,
        title: "Thumbnail",
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
    showPageCount: {
        type: ControlType.Boolean,
        title: "Page Count",
        enabledTitle: "Yes",
        disabledTitle: "No",
    },
})
